import React from "react"
import SectionHeader from "../../components/UI/elements/SectionHeader"
import Layout from "../../components/UI/layout"

const Risk = () => {
  return (
    <Layout>
      <SectionHeader headerText="Risk management" />
    </Layout>
  )
}

export default Risk
